import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { $api } from 'services/service';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { setAlert, setLoading } from 'redux/authSlice';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import fileDownload from 'js-file-download';
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';
import Button from '../../../components/Elements/Button/Button';
import ConfirmDialog from '../../../components/Elements/ConfirmDialog/ConfirmDialog';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Student__Create() {
    let history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();
    let cId: any = query.get('c');

    let { id } = useParams();
    const [updateMode, setUpdateMode] = useState(false);
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [classDetail, setClassDetail] = useState({
        name: '',
        facultyName: '',
        facultyId: ''
    });
    const [subjectTeaching, setSubjectTeaching] = useState({
        actualCreaditPoint: 0,
        totalCreditPoint: 0,
        subjectTeachingList: []
    });
    const [openMore, setOpenMore] = useState(false);
    const [modalStudentChangeClass, setModalStudentChangeClass] = useState(false);
    const [classChoose, setClassChoose] = useState('');
    const [faculties, setFaculties] = useState([]);
    const [faculty, setFaculty] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [studentDetail, setStudentDetail] = useState({
        startDate: new Date(),
        classId: cId,
        isGraduated: false,
        userName: '',
        fullName: '',
        birthDate: new Date(),
        identificationNumber: '',
        userInternalId: '',
        mobile: '',
        profilePicUrl: '',
        createLibAccount: false,
        hasIssue: null,
        issueDescription: '',
        gender: 1,
        studyStatus: 0,
        nickname: '',
        hometown: '',
        permanentAddress: '',
        ethnicity: '',
        religion: '',
        educationLevel: '',
        communistPartyJoinDate: null,
        officialPartyJoinDate: null,
        youthUnionJoinDate: null,
        fatherName: '',
        fatherOccupation: '',
        motherName: '',
        motherOccupation: '',
        spouseName: '',
        spouseOccupation: '',
        policySubject: '',
        previousOccupation: '',
        contactAddress: '',
        postGraduationWorkplace: '',
        id: '',
        userId: '',
        className: '',
        isActived: true,
        placeOfBirth: ''
    });
    const [studentDetailTmp, setStudentDetailTmp] = useState({
        startDate: new Date(),
        classId: cId,
        isGraduated: false,
        userName: '',
        fullName: '',
        birthDate: new Date(),
        identificationNumber: '',
        userInternalId: '',
        mobile: '',
        profilePicUrl: '',
        createLibAccount: false,
        hasIssue: null,
        issueDescription: '',
        gender: 1,
        studyStatus: 0,
        nickname: '',
        hometown: '',
        permanentAddress: '',
        ethnicity: '',
        religion: '',
        educationLevel: '',
        communistPartyJoinDate: new Date(),
        officialPartyJoinDate: new Date(),
        youthUnionJoinDate: new Date(),
        fatherName: '',
        fatherOccupation: '',
        motherName: '',
        motherOccupation: '',
        spouseName: '',
        spouseOccupation: '',
        policySubject: '',
        previousOccupation: '',
        contactAddress: '',
        postGraduationWorkplace: '',
        id: '',
        userId: '',
        className: '',
        isActived: true,
        placeOfBirth: ''
    });

    useEffect(() => {
        async function getStudentDetail(id) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.student.getDetail(id);
                if (res && res.result) {
                    setUpdateMode(true);
                    setStudentDetail(res.result);
                    let tmp = Object.assign({}, res.result);
                    setStudentDetailTmp(tmp);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                setUpdateMode(false);
            }
        }

        if (id && id !== 'them-moi') {
            getStudentDetail(id);
        }
    }, [updateMode]);

    useEffect(() => {
        //
        async function getClassDetail(id) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.class.getDetail(id);
                setClassDetail(res.result);
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        if (cId && cId?.length) {
            getClassDetail(cId);
        }
    }, []);
    useEffect(() => {
        async function getSubjectTeaching(id) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.schedule.subjectTeaching_GetByStudent(id);
                setSubjectTeaching(res.result);
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        if (id && id !== 'them-moi') {
            getSubjectTeaching(id);
        }
    }, []);

    async function submit(e) {
        e.preventDefault();
        let res;
        try {
            if (studentDetail.userInternalId === '') {
                show_ErrMsg('Vui lòng nhập mã số sinh viên');
                return;
            }
            if (studentDetail.fullName === '') {
                show_ErrMsg('Vui lòng nhập tên sinh viên');
                return;
            }
            if (studentDetail.userName === '') {
                show_ErrMsg('Vui lòng nhập địa chỉ mail');
                return;
            }
            if (studentDetail.userName !== '') {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(studentDetail.userName)) {
                    show_ErrMsg('Thông tin mail không đúng định dạng');
                    return;
                }
            }
            if (!studentDetail.classId || studentDetail.classId.length <= 0) {
                // @ts-ignore
                studentDetail.classId = cId;
            }
            dispatch(setLoading(true));
            if (updateMode) {
                res = await $api.student.updateStudent(studentDetail);
            } else {
                res = await $api.student.createStudent(studentDetail);
            }
            if (res && res.message) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                window.location.href = `/sinh-vien?c=` + studentDetail.classId;
                // history.push(`/sinh-vien?c=` + studentDetail.classId);
            } else if (res && res.exceptionMessage) {
                show_ErrMsg(res.exceptionMessage);
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function submitBlock(e) {
        e.preventDefault();
        let res;
        try {
            res = await $api.student.blockStudent({
                studentId: id,
                hasIssue: studentDetail.hasIssue,
                issueDescription: studentDetail.hasIssue ? studentDetail.issueDescription : ''
            });
            if (res && res.message) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                history.push(`/sinh-vien?c=` + studentDetail.classId);
            } else if (res && res.exceptionMessage) {
                show_ErrMsg(res.exceptionMessage);
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function cancel() {
        setStudentDetail(studentDetailTmp);
        // history.push(`/sinh-vien?c=` + studentDetail.classId);
        history.goBack();
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    async function exportStudentDetail() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.student.exportStudentDetail(id);
            fileDownload(res, `Thong-tin-sinh-vien-${studentDetail.fullName}-ngay-xuat-ket-qua-${moment((new Date())).format('DD/MM/YYYY')}.xlsx`);
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    useEffect(() => {
        async function getFaculties() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.faculties.GetAllAndClass();
                if (res && res.result.length > 0) {
                    setFaculties(res.result);
                    setFaculty(res.result[0].id);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getFaculties();
    }, []);

    function bindingClassChooseWithRequestParam() {
        if (cId) {
            // // @ts-ignore
            // const faculty = faculties.find(faculty => faculty.id === '');
            // // @ts-ignore
            // const classInfo = faculty.classes.find(cls => cls.id === cId);
            // // @ts-ignore
            // setClassChoose(() => `Khoa: ${faculty.name} - Lớp: ${classInfo.name}`);
        } else {
            setClassChoose(() => '');
        }
    }

    useEffect(() => {
        if (faculties.length > 0) {
            bindingClassChooseWithRequestParam();
        }
    }, [cId, faculties]);

    async function studentChangeClassAction() {
        setChooseClassByStudentClassId();
        setModalStudentChangeClass(prevState => !prevState);
    }

    function setChooseClassByStudentClassId() {
        for (let faculty of faculties) {
            for (let classItem of faculty.classes) {
                if (classItem.id === cId) {
                    setFaculty(() => faculty.id);
                    setClassChoose(() => classItem.id);
                }
            }
        }
        return null; // Trả về null nếu không tìm thấy lớp học
    }

    // @ts-ignore
    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div className='___title__box'>
                            <a href={`/lop-hoc?f=${classDetail?.facultyId}`}>
                                {classDetail?.facultyName}
                            </a>
                            <div className='___title__box__last'>{`>`}</div>
                            <a
                                href={`/sinh-vien?c=${cId}&f=${classDetail?.facultyId}`}>
                                {classDetail?.name}
                            </a>
                            <div className='___title__box__last'>{`>`}</div>
                            {updateMode
                                ? `${studentDetail.fullName} (${studentDetail.userInternalId})`
                                : 'Thêm mới sinh viên'}
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='w-full py-4'>
                        <div className={'row w-full flex flex-row justify-between pl-4'}>
                            <div className='label-form font-bold'>
                                Thông tin sinh viên:
                            </div>
                            <div>
                                <button type='button'
                                        onClick={() => studentChangeClassAction()}
                                        className='mr-2 focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
                                    <i className={'fas fa-sitemap'}></i> Chuyển lớp
                                </button>
                                <button type='button' onClick={event => exportStudentDetail()}
                                        className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'>
                                    <i className={'fas fa-file-alt mr-1'}></i> Xuất thông tin
                                </button>
                            </div>
                        </div>
                        <div>
                            <form
                                className='mt-4 flex item-center flex-row flex-wrap w-full'
                                onSubmit={(e) => submit(e)}
                                onKeyDown={(e) =>
                                    e.key === 'Enter' && submit(e)
                                }>
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Mã số sinh viên
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập mã số sinh viên'
                                        value={
                                            studentDetail.userInternalId || ''
                                        }
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                userInternalId: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Tên sinh viên
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập tên sinh viên'
                                        value={studentDetail.fullName || ''}
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                fullName: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Địa chỉ email
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                        placeholder='Nhập địa chỉ email'
                                        value={studentDetail.userName || ''}
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                userName: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Số điện thoại
                                    </div>
                                    <input
                                        name='text'
                                        type='tel'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập số điện thoại'
                                        value={studentDetail.mobile || ''}
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                mobile: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        CMND/CCCD
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập chứng minh thư'
                                        value={
                                            studentDetail.identificationNumber ||
                                            ''
                                        }
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                identificationNumber:
                                                e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Ngày sinh
                                    </div>
                                    <DatePicker
                                        className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        selected={
                                            new Date(studentDetail.birthDate)
                                        }
                                        onChange={(date) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                birthDate: date
                                            });
                                        }}
                                        dateFormat='dd/MM/yyyy'
                                    />
                                </div>
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Giới tính
                                    </div>
                                    <select
                                        className='input-custom block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                        // @ts-ignore
                                        value={studentDetail.gender}
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                // @ts-ignore
                                                gender:
                                                e.target.value
                                            });
                                        }}>
                                        <option
                                            // @ts-ignore
                                            value={1}
                                            // @ts-ignore
                                            key={1}>
                                            Nam
                                        </option>
                                        <option
                                            // @ts-ignore
                                            value={2}
                                            // @ts-ignore
                                            key={2}>
                                            Nữ
                                        </option>
                                        <option
                                            // @ts-ignore
                                            value={3}
                                            // @ts-ignore
                                            key={3}>
                                            Khác
                                        </option>
                                    </select>
                                </div>
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Quê quán
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập quê quán'
                                        value={
                                            studentDetail.hometown ||
                                            ''
                                        }
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                hometown:
                                                e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Nơi sinh
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập nơi sinh'
                                        value={
                                            studentDetail.placeOfBirth ||
                                            ''
                                        }
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                placeOfBirth:
                                                e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Địa chỉ thường trú
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập địa chỉ thường trú'
                                        value={
                                            studentDetail.permanentAddress ||
                                            ''
                                        }
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                permanentAddress:
                                                e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                {
                                    openMore ? <>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Trình độ học vấn
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập trình độ học vấn'
                                                value={
                                                    studentDetail.educationLevel ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        educationLevel:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Tên thường gọi
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập tên thường gọi'
                                                value={
                                                    studentDetail.nickname ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        nickname:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Dân tộc
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập dân tộc'
                                                value={
                                                    studentDetail.ethnicity ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        ethnicity:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Tôn giáo
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập tôn giáo'
                                                value={
                                                    studentDetail.religion ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        religion:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Ngày tham gia Đảng CSVN
                                            </div>
                                            <DatePicker
                                                className='appearance-none block
                                        w-full px-3 py-2 border border-gray-300
                                        placeholder-gray-500 rounded-md
                                        focus:outline-none focus:ring-1
                                        focus:ring-blue-700'
                                                selected={
                                                    new Date(studentDetail.communistPartyJoinDate)
                                                }
                                                onChange={(date) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        communistPartyJoinDate: date
                                                    });
                                                }}
                                                dateFormat='dd/MM/yyyy'
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Ngày vào Đảng chính thức
                                            </div>
                                            <DatePicker
                                                className='appearance-none block
                                        w-full px-3 py-2 border border-gray-300
                                        placeholder-gray-500 rounded-md
                                        focus:outline-none focus:ring-1
                                        focus:ring-blue-700'
                                                selected={
                                                    new Date(studentDetail.officialPartyJoinDate)
                                                }
                                                onChange={(date) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        officialPartyJoinDate: date
                                                    });
                                                }}
                                                dateFormat='dd/MM/yyyy'
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Ngày kết nạp vào Đoàn TNCS
                                            </div>
                                            <DatePicker
                                                className='appearance-none block
                                        w-full px-3 py-2 border border-gray-300
                                        placeholder-gray-500 rounded-md
                                        focus:outline-none focus:ring-1
                                        focus:ring-blue-700'
                                                selected={
                                                    new Date(studentDetail.youthUnionJoinDate)
                                                }
                                                onChange={(date) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        youthUnionJoinDate: date
                                                    });
                                                }}
                                                dateFormat='dd/MM/yyyy'
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Họ tên cha
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập họ tên cha'
                                                value={
                                                    studentDetail.fatherName ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        fatherName:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Nghề nghiệp của cha
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập nghề nghiệp của cha'
                                                value={
                                                    studentDetail.fatherOccupation ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        fatherOccupation:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Họ tên mẹ
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập họ tên mẹ'
                                                value={
                                                    studentDetail.motherName ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        motherName:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Nghề nghiệp của mẹ
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập nghề nghiệp của mẹ'
                                                value={
                                                    studentDetail.motherOccupation ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        motherOccupation:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Họ tên vợ (chồng)
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập họ tên vợ (chồng)'
                                                value={
                                                    studentDetail.spouseName ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        spouseName:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Nghề nghiệp của vợ (chồng)
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập nghề nghiệp của vợ (chồng)'
                                                value={
                                                    studentDetail.spouseOccupation ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        spouseOccupation:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Đối tượng chính sách
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập đối tượng chính sách'
                                                value={
                                                    studentDetail.policySubject ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        policySubject:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Nghề nghiệp trước khi vào học
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập nghề nghiệp trước khi vào học'
                                                value={
                                                    studentDetail.previousOccupation ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        previousOccupation:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Địa chỉ liên lạc
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập địa chỉ liên lạc'
                                                value={
                                                    studentDetail.contactAddress ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        contactAddress:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Nơi làm việc sau khi kết thúc khóa học
                                            </div>
                                            <input
                                                name='text'
                                                type='text'
                                                className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                placeholder='Nhập nơi làm việc sau khi kết thúc khóa học'
                                                value={
                                                    studentDetail.postGraduationWorkplace ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        postGraduationWorkplace:
                                                        e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={'col-3 mb-1'}>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Ngày bắt đầu
                                            </div>
                                            <DatePicker
                                                className='appearance-none block
                                        w-full px-3 py-2 border border-gray-300
                                        placeholder-gray-500 rounded-md
                                        focus:outline-none focus:ring-1
                                        focus:ring-blue-700'
                                                selected={
                                                    new Date(studentDetail.startDate)
                                                }
                                                onChange={(date) => {
                                                    setStudentDetail({
                                                        ...studentDetail,
                                                        startDate: date


                                                    });
                                                }}
                                                dateFormat='dd/MM/yyyy'
                                            />
                                        </div>
                                    </> : ''
                                }
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Tình trạng học tập
                                    </div>
                                    <select
                                        className='input-custom block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                        // @ts-ignore
                                        value={studentDetail.studyStatus}
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                // @ts-ignore
                                                studyStatus: e.target.value
                                            });
                                        }}>
                                        <option
                                            // @ts-ignore
                                            value={0}
                                            // @ts-ignore
                                            key={0}>
                                            Đang học
                                        </option>
                                        <option
                                            // @ts-ignore
                                            value={1}
                                            // @ts-ignore
                                            key={1}>
                                            Tạm hoãn
                                        </option>
                                        <option
                                            // @ts-ignore
                                            value={2}
                                            // @ts-ignore
                                            key={2}>
                                            Đã nghỉ
                                        </option>
                                    </select>
                                </div>
                                <div className={'col-3 mb-1'}>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Tình trạng tốt nghiệp
                                    </div>
                                    <select
                                        className='input-custom block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                        // @ts-ignore
                                        value={studentDetail.isGraduated}
                                        onChange={(e) => {
                                            setStudentDetail({
                                                ...studentDetail,
                                                // @ts-ignore
                                                isGraduated:
                                                    e.target.value === 'true'
                                            });
                                        }}>
                                        <option
                                            // @ts-ignore
                                            value={false}
                                            // @ts-ignore
                                            key={false}>
                                            Chưa tốt nghiệp
                                        </option>
                                        <option
                                            // @ts-ignore
                                            value={true}
                                            // @ts-ignore
                                            key={true}>
                                            Đã tốt nghiệp
                                        </option>
                                    </select>
                                </div>
                                {
                                    <div className={'col-12'}>
                                        <div className='row pt-4 w-full flex flex-row justify-center'>
                                            <button type='button' onClick={() => setOpenMore(prevState => !prevState)}
                                                    className='text-blue-700 hover:text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800'>
                                                {
                                                    <><i
                                                        className={(openMore ? 'fas fa-eye-slash' : 'fas fa-eye') + ' mr-1'}></i> {openMore ? 'Thu gọn thông tin' : 'Hiển thị thêm thông tin'}</>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }
                                <div className='row flex flex-row justify-center w-full'>
                                    <div className='mt-4 flex flex-row justify-between col-12 '>
                                        <div className={'col-6'}>
                                            <div className={'flex flex-row justify-end'}>
                                                <label htmlFor={'libary'}
                                                       className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                    Tạo tài khoản thư viện cho học viên:
                                                </label>
                                                <input
                                                    id={'libary'}
                                                    type='checkbox'
                                                    className='h-4 w-4 mt-0.5 ml-4'
                                                    onChange={(e) => {
                                                        setStudentDetail({
                                                            ...studentDetail,
                                                            // @ts-ignore
                                                            createLibAccount: e.target.checked
                                                        });
                                                    }}
                                                    // @ts-ignore
                                                    checked={studentDetail.createLibAccount}
                                                /></div>
                                        </div>

                                        <div className={'col-6'}>
                                            {
                                                updateMode ? <div className={'flex flex-row justify-end w-full'}>
                                                        <form
                                                            className='flex item-center flex-col justify-center w-full'
                                                            onSubmit={(e) => submitBlock(e)}
                                                            onKeyDown={(e) =>
                                                                e.key === 'Enter' && submitBlock(e)
                                                            }>

                                                            <div className=' flex center'>
                                                                <div
                                                                    className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                                    Chặn tính năng trên app:
                                                                </div>
                                                                <input
                                                                    type='checkbox'
                                                                    className='h-4 w-4 mt-0.5 ml-4'
                                                                    onChange={(e) => {
                                                                        setStudentDetail({
                                                                            ...studentDetail,
                                                                            // @ts-ignore
                                                                            hasIssue: e.target.checked
                                                                        });
                                                                    }}
                                                                    // @ts-ignore
                                                                    checked={studentDetail.hasIssue}
                                                                />
                                                            </div>
                                                            {
                                                                studentDetail.hasIssue ?
                                                                    <div>
                                                                        <div
                                                                            className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                                            Lý do chặn sinh viên:
                                                                        </div>
                                                                        <textarea
                                                                            name='block-reason'
                                                                            className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                                                            placeholder='Lý do...'
                                                                            value={studentDetail.issueDescription}
                                                                            onChange={(e) => {
                                                                                setStudentDetail({
                                                                                    ...studentDetail,
                                                                                    issueDescription: e.target.value
                                                                                });
                                                                            }}
                                                                        />
                                                                        <div className='flex justify-center'>
                                                                            <button
                                                                                type='submit'
                                                                                className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-blue-800
								hover:bg-blue-900 focus:outline-none mt-3'>
                                                                                Lưu lại
                                                                            </button>
                                                                        </div>
                                                                    </div> : ''
                                                            }
                                                        </form>
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                    <div className='flex justify-center'>
                                        <button
                                            type='button'
                                            className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-red-600
								hover:bg-red-700 focus:outline-none mt-3 mr-4'
                                            onClick={cancel}>
                                            Hủy Bỏ
                                        </button>
                                        <button
                                            type='submit'
                                            className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-blue-800
								hover:bg-blue-900 focus:outline-none mt-3'>
                                            Lưu lại
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    updateMode ? <div className='page__content bg-white mt-3'>
                        <div className='container-fluid py-4'>
                            <div className='label-form'>
                                <div className={'row flex flex-row w-full justify-center  py-4 '}>
                                    <h3 className={'font-bold text-center text-2xl'}>Danh sách môn học tham gia</h3>
                                </div>
                                <div className={'flex-row w-full justify-between py-4 font-bold'}>
                                    <div className={''}>
                                        Số tín chỉ hoàn thành: <span
                                        className={'text-red-600'}>{subjectTeaching?.actualCreaditPoint}</span>
                                    </div>
                                    <div className={''}>
                                        Tổng số tín chỉ: <span
                                        className={'text-red-600'}>{subjectTeaching?.totalCreditPoint}</span>
                                    </div>
                                </div>
                                {
                                    subjectTeaching.subjectTeachingList?.length > 0 ?
                                        <table className='min-w-full divide-y divide-gray-200'>
                                            <thead className='bg-gray-50'>
                                            <tr>
                                                <th
                                                    scope='col'
                                                    className='px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                    Khoa
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                    Tên môn
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                    Số tín chỉ
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                    Trạng thái
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
                                            {subjectTeaching.subjectTeachingList.map((item) => (
                                                <tr
                                                    className='hover:bg-gray-100'
                                                    key={item.id}>
                                                    <td className='px-6 py-4 whitespace-nowrap'>
                                                        <div
                                                            className='flex text-left items-start text-sm text-gray-900'>
                                                            {item.subject.facultyName || ''}
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 whitespace-nowrap'>
                                                        <div className='flex items-start text-sm text-gray-900'>
                                                            {item.subject.name || ''}
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 whitespace-nowrap'>
                                                        <div
                                                            className='flex text-center items-start text-sm text-gray-900'>
                                                            {item.subject.creditPoint || ''}
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 whitespace-nowrap'>
                                                        <div
                                                            className='flex text-center items-start text-sm text-gray-900 font-bold'>
                                                            {item.subjectStatus || ''}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        : <div
                                            className={'mx-2 font-medium font-bold w-full flex flex-row justify-center'}> Chưa
                                            có thông tin môn học!</div>
                                }
                            </div>
                        </div>
                    </div> : ''
                }
            </div>
            <Modal
                show={modalStudentChangeClass}
                aria-labelledby='contained-modal-title-vcenter'
                onHide={() => setModalStudentChangeClass(false)}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Chuyển lớp học
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className={'col-12 mb-1'}>
                        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Khoa
                            </div>
                            <select
                                className='input-custom block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                // @ts-ignore
                                value={faculty}
                                onChange={(e) => {
                                    setFaculty(() => e.target.value);
                                }}>
                                {
                                    faculties.length > 0 ? faculties.map(faculty => {
                                        return <option
                                            // @ts-ignore
                                            value={faculty.id}
                                            // @ts-ignore
                                            key={faculty.id}>
                                            {faculty.name}
                                        </option>;
                                    }) : ''
                                }
                            </select>
                        </div>
                        <div className={'col-12 mb-1'}>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Lớp
                            </div>
                            <select
                                className='input-custom block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                // @ts-ignore
                                value={studentDetail.classId}
                                onChange={(e) => {
                                    setStudentDetail({
                                        ...studentDetail,
                                        classId: e.target.value
                                    });
                                }}>
                                <option
                                    value={''}
                                    key={'Lớp'}>
                                    Chọn lớp
                                </option>
                                {
                                    faculties.length > 0 ? faculties.find(filterVal => filterVal.id === faculty)?.classes.length > 0 ?
                                        faculties.find(filterVal => filterVal.id === faculty).classes.map(classVal => {
                                            return <option
                                                // @ts-ignore
                                                value={classVal.id}
                                                // @ts-ignore
                                                key={classVal.id}>
                                                {classVal.name}
                                            </option>;
                                        }) : '' : ''
                                }

                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        onClick={(e) => setConfirmDialog({
                            isOpen: true,
                            title: 'Xác nhận',
                            subTitle: 'Bạn xác nhận muốn chuyển lớp cho sinh viên ?'
                        })}>
                        <Button
                            label={updateMode ? 'Lưu lại' : 'Tạo mới'}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={submit}
            />
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default Student__Create;
