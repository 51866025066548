import axiosClient from 'config/axiosClient';

const _url = '/Api/Attendances';
const attendanceService = {
    getPagingExamAttendance: (id: string) => {
        return axiosClient.post(_url + '/GetPagingExamAttendance', { subjectTeachingExamId: id });
    },
    getStudent: (id: string) => {
        return axiosClient.post(_url + '/GetStudents', { subjectScheduleId: id });
    },
    saveAttendances: (attendances: any[]) => {
        return axiosClient.post(_url + '/Save', attendances);
    },
    saveListExam: (attendances) => {
        return axiosClient.post(_url + '/SaveListExam', attendances);
    },
    exportAttendances: (id: string) => {
        return axiosClient.get(_url + '/Export', {
            params: { subjectTeachingId: id }, responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    }
};

export default attendanceService;
