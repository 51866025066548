import axiosClient from 'config/axiosClient';

const _url = '/Api';
const examService = {
    subjectTeachingExam_get_all: (id) => {
        return axiosClient.post(_url + '/exams/get-paging', { subjectTeachingId: id });
    },
    subjectTeachingExam_get: (id) => {
        return axiosClient.post(_url + '/exams/get', { id: id });
    },

    subjectTeachingExamGetBySubjectTeaching: (id) => {
        return axiosClient.post(_url + '/exam-result/get-by-subject-teaching?subjectTeachingId=' + id);
    },
    subjectTeachingExam_save: (option) => {
        return axiosClient.post(_url + '/exams/save', option);
    },
    subjectTeachingExam_update: (option) => {
        return axiosClient.post(_url + '/exams/update', option);
    },
    subjectTeachingExam_delete: (id) => {
        return axiosClient.post(_url + '/exams/delete', { id: id });
    },
    examGetListExamResultForOnline: (options) => {
        return axiosClient.post(_url + '/exam-result/ListExamResultForOnline', options);
    },
    examGetExamResultForOnline: (options) => {
        return axiosClient.post(_url + '/exam-result/ExamResultForOnline', options);
    },
    examSaveBySubjectTeaching: (id, option) => {
        return axiosClient.post(_url + '/exam-result/save-by-subject-teaching?subjectTeachingId=' + id, option);
    },
    exam_save_list: (option) => {
        return axiosClient.post(_url + '/exam-result/save-list', option);
    },
    exam_import: (formData, id) => {
        return axiosClient.post(_url + '/exam-result/import-exam-result?subjectTeachingId=' + id, formData);
    },
    exam_export: (id) => {
        return axiosClient.get(_url + '/exam-result/export-by-subject-teaching', {
            params: {
                subjectTeachingId: id
            },
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    },
    examOnlineResultExport: (examAttemptId) => {
        return axiosClient.get(_url + '/exam-result/export-online-exam', {
            params: {
                examAttemptId: examAttemptId
            },
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    },
    examOnlineResultExportAll: (subjectTeachingExamId) => {
        return axiosClient.get(_url + '/exam-result/export-online-exam', {
            params: {
                subjectTeachingExamId: subjectTeachingExamId
            },
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    },
    examBySubjectTeachingProcess: (id) => {
        return axiosClient.get(_url + '/exam-result/export-subject-progress', {
            params: {
                subjectTeachingId: id
            },
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    },
    examExportWithClassOption: (options) => {
        return axiosClient.get(_url + '/exam-result/export-semester-exam', {
            params: options,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        });
    }
};

export default examService;
