import React, { useEffect, useRef, useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import { setAlert, setLoading } from '../../redux/authSlice';
import { $api } from '../../services/service';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import ErrorDialog from '../../components/Elements/ErrorDialog/ErrorDialog';
import check from '../../assets/icons/checkbox-selected.svg';
import un_check from '../../assets/icons/checkbox-unselected.svg';
import './attendance.scss';
import { Modal } from 'react-bootstrap';
import Webcam from 'react-webcam';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function AttendanceExam() {
    let history = useHistory();
    const ref = useRef();
    const query = useQuery();
    const dispatch = useDispatch();
    let subjectTeachingExamId: any = query.get('id');
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [attendances, setAttendances] = useState<any>([]);
    const [allCheck, setAllCheck] = useState(99);
    const [actionPhoto, setActionPhoto] = useState(false);
    const videoConstraints = {
        width: 500,
        height: 500,
        facingMode: 'user'
    };
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        () => {
            // @ts-ignore
            const imageSrc = webcamRef.current.getScreenshot();
            const base64ToBlob = (base64) => {
                const parts = base64.split(';base64,');
                const contentType = parts[0].split(':')[1];
                const byteCharacters = atob(parts[1]);
                const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
                const byteArray = new Uint8Array(byteNumbers);
                return new Blob([byteArray], { type: contentType });
            };
            const blob = base64ToBlob(imageSrc);
            uploadAttendanceImage(blob);
        },
        [webcamRef]
    );

    async function getAttendance() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.attendance.getPagingExamAttendance(subjectTeachingExamId);
            if (res['result'].length > 0) {
                setAttendances(res.result);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    useEffect(() => {
        getAttendance();
    }, []);


    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    async function setStatus(index: number, status: number) {
        setAttendances(attendances.map((att, i) => {
            return i == index ? { ...att, status: status } : { ...att };
        }));

    }

    async function setNote(index: number, note: string) {
        setAttendances(attendances.map((att, i) => {
            return i == index ? { ...att, notes: note } : { ...att };
        }));

    }

    function getCountStatus(status: number) {
        return attendances.filter(value => value.status == status).length;
    }

    function pageBack() {
        history.goBack();
    }

    async function uploadAttendanceImage(blob: Blob) {
        // @ts-ignore
        const studentAttendanceActive = JSON.parse(localStorage.getItem('STUDENT_ATTENDANCE_ACTIVE'));
        const formData = new FormData();
        formData.append('file', blob, 'image.jpg');
        formData.append('studentId', studentAttendanceActive.studentId);
        formData.append('status', '1');
        formData.append('subjectTeachingExamId', subjectTeachingExamId);
        try {
            dispatch(setLoading(true));
            const res: any = await $api.attendance.saveListExam(
                formData
            );
            if (res && res.message) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                window.location.reload();
                return;
            }
            if (res && res.errors) {
                dispatch(setLoading(false));
                show_ErrMsg(res?.errors?.exceptionMessage ? res?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                return;
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            setErrDialog({
                isOpen: true,
                subTitle: errMsg
            });
        }
    }
    async function uploadAttendanceImageByChoose(event) {
        // @ts-ignore
        const studentAttendanceActive = JSON.parse(localStorage.getItem('STUDENT_ATTENDANCE_ACTIVE'));
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('studentId', studentAttendanceActive.studentId);
        formData.append('status', '1');
        formData.append('subjectTeachingExamId', subjectTeachingExamId);
        try {
            dispatch(setLoading(true));
            const res: any = await $api.attendance.saveListExam(
                formData
            );
            if (res && res.message) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                window.location.reload();
                return;
            }
            if (res && res.errors) {
                dispatch(setLoading(false));
                show_ErrMsg(res?.errors?.exceptionMessage ? res?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                return;
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            setErrDialog({
                isOpen: true,
                subTitle: errMsg
            });
        } finally {
            event.target.value = '';
        }
    }
    function setALL(status: number) {
        if (allCheck !== status) {
            setAttendances(attendances.map((att, i) => {
                return { ...att, status: status };
            }));
            setAllCheck(status);
        }
    }

    function imageActionEvent(attendance, i) {
        localStorage.setItem('STUDENT_ATTENDANCE_ACTIVE', JSON.stringify(attendance));
        setActionPhoto(true);
    }

    return (
        <DefaultLayout>
            <div className='attendance page'>
                <div className='page__header'>
                    <div className={'flex flex-row align-items-center'}>
                        <button className={'btn btn-sm btn-light'} onClick={() => pageBack()}>
                            <i className={'fas fa-arrow-left mr-0.5'}></i>
                            Quay lại
                        </button>
                        <div className='___title flex items-center'>
                            <div className='___title__decorate mx-2' />
                            <div>Điểm danh môn học</div>
                        </div>
                    </div>
                </div>
                <input
                    type='file'
                    id='attendanceImage'
                    className='hidden'
                    accept='image/png, image/jp2, image/jpeg,image/jpg, image/webp, image/webp'
                    onChange={(e) => {
                        uploadAttendanceImageByChoose(e);
                    }}
                />
                <div className='page__content bg-white mt-3'>
                    <div className='actions flex items-center  justify-between'>
                        <div
                            className='flex flex-row mx-sm-3 bg-white px-sm-3 py-sm-2 radius-10 w-100'
                            style={{ overflow: 'auto' }}>
                            <table className='table table-light'>
                                <thead>
                                <tr className={'cursor-pointer'}>
                                    <th style={{ width: '5%' }}>STT</th>
                                    <th style={{ width: '10%' }}>Ảnh</th>
                                    <th style={{ width: '10%' }}>MSSV</th>
                                    <th style={{ width: '20%' }}>Sinh viên</th>
                                    <th style={{ width: '10%' }}>Ghi chú</th>
                                    <th style={{ width: '10%' }}>
                                        <div onClick={() => setALL(1)}> Có mặt <i
                                            className='fas fa-check-double'></i></div>
                                    </th>
                                    <th style={{ width: '10%' }}>
                                        <div onClick={() => setALL(2)}>
                                            Đi trễ <i className='fas fa-check-double'></i>
                                        </div>
                                    </th>
                                    <th style={{ width: '12%' }}>
                                        <div onClick={() => setALL(3)}>Vắng có phép <i
                                            className='fas fa-check-double'></i></div>
                                    </th>
                                    <th style={{ width: '13%' }}>
                                        <div onClick={() => setALL(4)}>
                                            Vắng không phép <i className='fas fa-check-double'></i>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    attendances.length > 0 ? attendances.map((attendance, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ width: '5%' }} data-label='STT'>
                                                    <b>{i + 1}</b>
                                                </td>
                                                <td style={{ width: '10%' }} data-label='Ảnh'>
                                                    {
                                                        attendance.imageUrl ?
                                                            <div className={'w-100 flex justify-center'}><img
                                                                onClick={() => window.open(attendance.imageUrl, '_blank')}
                                                                width={100} height={100} src={attendance.imageUrl}
                                                                alt='' /></div> :
                                                            <div onClick={() => imageActionEvent(attendance, i)}>
                                                                <i className={'far fa-image text-xl'}></i>
                                                            </div>
                                                    }
                                                </td>
                                                <td style={{ width: '10%' }} data-label='MSSV'>
                                                    <b className='span-center'>
                                                        <span
                                                            className={'text-danger'}>{attendance.userInternalId}</span>
                                                    </b>
                                                </td>
                                                <td style={{ width: '20%' }} data-label='Sinh viên'>
                                                    <b>
                                                        <span className={'text-danger'}>{attendance.name}</span>
                                                    </b>
                                                </td>
                                                <td style={{ width: '10%' }} data-label='Ghi chú'>
                                                    <input defaultValue={attendance.notes || ''}
                                                           onChange={event => setNote(i, event.target.value)} />
                                                </td>
                                                <td align='center' style={{ width: '10%' }} data-label='Có mặt'
                                                    onClick={() => setStatus(i, 1)}>
                                                    <img
                                                        src={attendance.status == 1 ? check : un_check}
                                                        alt='' />
                                                </td>
                                                <td align='center' style={{ width: '10%' }} data-label='Đi trễ'
                                                    onClick={() => setStatus(i, 2)}>
                                                    <img
                                                        src={attendance.status == 2 ? check : un_check}
                                                        alt='' />
                                                </td>
                                                <td align='center' style={{ width: '12%' }} data-label='Vắng có phép'
                                                    onClick={() => setStatus(i, 3)}>
                                                    <img
                                                        src={attendance.status == 3 ? check : un_check}
                                                        alt='' />
                                                </td>
                                                <td align='center' style={{ width: '13%' }} data-label='Vắng không phép'
                                                    onClick={() => setStatus(i, 4)}>
                                                    <img
                                                        src={attendance.status == 4 ? check : un_check}
                                                        alt='' />
                                                </td>
                                            </tr>
                                        );
                                    }) : <tr>
                                        <td colSpan={8} align={'center'}><span className={'text-danger'}>Không có dữ liệu sinh viên để tiến hành điểm danh!</span>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='flex-row flex items-center justify-between w-100'>
                        <div className={''}>
                            <i className={'fas fa-user'}></i>
                            <span className={'mx-0.5'}>Tổng số SV có mặt:</span>
                            <span className={'text-danger'}><b>{getCountStatus(1)}</b></span>
                        </div>
                        <div>
                            <i className={'fas fa-user'}></i>
                            <span className={'mx-0.5'}>Tổng số SV đi trễ:</span>
                            <span className={'text-danger'}><b>{getCountStatus(2)}</b></span>
                        </div>
                        <div>
                            <i className={'fas fa-user'}></i>
                            <span className={'mx-0.5'}>Tổng số SV vắng có phép:</span>
                            <span className={'text-danger'}><b>{getCountStatus(3)}</b></span>
                        </div>
                        <div>
                            <i className={'fas fa-user'}></i>
                            <span className={'mx-0.5'}>Tổng số SV vắng không phép:</span>
                            <span className={'text-danger'}><b>{getCountStatus(4)}</b></span>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={actionPhoto}
                aria-labelledby='contained-modal-title-vcenter'
                onHide={() => setActionPhoto(false)}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Tùy chọn ảnh
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'w-100 flex justify-center'}>
                        <div className={'flex flex-col'}>
                            <Webcam
                                audio={false}
                                height={400}
                                ref={webcamRef}
                                screenshotFormat='image/jpeg'
                                width={400}
                                videoConstraints={videoConstraints}
                            />
                            <button className={'btn btn-info mt-2'} onClick={capture}><i
                                className={'fas fa-camera mr-1'}></i>Chụp ảnh
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={'w-100 flex justify-between align-middle'}>
                        <button className={'btn btn-secondary'} onClick={() => {
                            setActionPhoto(false);
                            localStorage.removeItem('STUDENT_ATTENDANCE_ACTIVE');
                        }}>
                            <i className={'fas fa-close mr-1'}></i>
                            Đóng
                        </button>
                        <button className={'btn btn-success'} onClick={() => document.getElementById('attendanceImage').click()}>
                            <i className={'fas fa-file mr-1'}></i>
                            Chọn ảnh có sẵn
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default AttendanceExam;